import { Box } from "@mui/system";
import * as React from "react";
import CustomInput from "../CustomInput/CustomInput";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
} from "./SignInAndSignUp";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { CustomButton } from "../CustomButton/CustomButton";
import { CircularProgress, Typography } from "@mui/material";
// import { useForm } from "./useForm";
import Auth from "@aws-amplify/auth";
import ResetPassword from "./ResetPassword";
import validateFPEmail from "./SignInSignUpValidationRules/ForgotPasswordValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";

const ForgotPassword = ({
  emailVerifyLoader,
  setEmailVerifyLoader,
  setEmailVerification,
  setForgotPassword,
  setResetPasswordOpenFirst,
  values,
  handleChange,
  passwordResetFC,
  resetPasswordLoader,
  emailVerification,
  setErrors,
  errors,
  setValues,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  // const { values, handleChange } = useForm({});
  const [emailView, setEmailView] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validateFPEmail(values)).length !== 0) {
      setErrors(validateFPEmail(values));
      return;
    } else {
      setEmailVerifyLoader(true);
      try {
        const forgot = await Auth.forgotPassword(values.verificationEmail);
        if (forgot) {
          setEmailVerifyLoader(false);
          setEmailVerification(true);
          setResetPasswordOpenFirst(false);
          setEmailView(false);
        }
      } catch (error) {
        setEmailVerifyLoader(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  return (
    <React.Fragment>
      {emailView ? (
        <React.Fragment>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" component="div" gutterBottom>
              Forgot password? Please enter your email
            </Typography>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <React.Fragment>
              <Box sx={inputBoxStyle}>
                <CustomInput
                  id="verificationEmail-1"
                  placeholder="Email"
                  name="verificationEmail"
                  error={errors.email}
                  value={values.verificationEmail || ""}
                  onChange={handleChange("verificationEmail")}
                >
                  <EmailOutlinedIcon sx={inputIconStyle} />
                </CustomInput>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                {emailVerifyLoader ? (
                  <CustomButton loading="true" disabled>
                    <CircularProgress size={20} color="inherit" />
                  </CustomButton>
                ) : (
                  <CustomButton primary type="submit">
                    Next
                  </CustomButton>
                )}
              </Box>
            </React.Fragment>
          </form>
        </React.Fragment>
      ) : (
        <ResetPassword
          passwordResetFC={passwordResetFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}
    </React.Fragment>
  );
};

export default ForgotPassword;
