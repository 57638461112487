export default function cognitoValidation(error) {
  let errors = {};

  if (error.code === "UserNotFoundException") {
    errors.email = "User does not exist";
  } else if (error.code === "UserNotConfirmedException") {
    errors.email = "User Not Confirmed";
  } else if (
    error.code === "NotAuthorizedException: Password attempts exceeded"
  ) {
    errors.password = "Password attempts exceeded";
  } else if (error.code === "NotAuthorizedException") {
    errors.password = "Invalid Password";
  } else if (error.code === "InvalidPasswordException") {
    errors.password = "Please match the password type.";
  } else if (error.code === "InvalidParameterException") {
    errors.code = "Invalid code entered";
  } else if (error.code === "CodeMismatchException") {
    errors.verificationCode = "Invalid Verification Code";
  } else if(error.code === 'LimitExceededException'){
    errors.verificationCode = 'Limit Exceed, please try after sometime';
  }

  return errors;
}
