export default function validateFPEmail(values) {
  let errors = {};

  if (!values.verificationEmail) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.verificationEmail)) {
    errors.email = "Email address is invalid";
  }
  return errors;
}
