import React from "react";


import App from "../src/App";

import "./App.scss";

function AppLoad() { 
  return  <App />;
  //return ;
}
export default AppLoad;
