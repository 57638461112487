import React, { useState, useRef } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import AppIcon from '../App-icon.png';
// import logoLight from '../logo-light.png'
import logoDark from '../logo-dark.png';
import config from '../../../config/aws-exports'; 

import axios from 'axios';
import Swal from 'sweetalert2'; 
import '../App.css'


import { Button } from "@mui/material";
const useStyles = makeStyles((theme) => ({
    imageUploader: {
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    imagePreview: {
        position: "relative",
        display: "inline-block"
    },
    previewImage: {
        width: "150px",
        height: "60px",
        objectFit: "cover",
        borderRadius: "10%",
        marginRight: '10px'
    },
    editButton: {
        position: "absolute",
        bottom: 0,
        right: "0",
        left: "140px",
        top: "0px",
        transform: "translate(50%, 50%)",
        background: "#217fb5",
        borderRadius: "50%",
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    editIcon: {
        color: '#217FB5',
        cursor: 'pointer',
    },
    uploadButton: {
        marginTop: "10px",
        marginRight: "10px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px" // Add gap between label and image
    },
    fileInput: {
        display: "none"
    },
    inputfield: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

    },
    imgs: {
        display: "grid",
        gridTemplateRows: "repeat(1, 2fr)",

    },
    maindiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        padding: '0 12px'
    },
    subdiv: {
        width: '33vw',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        gap: '8px',
    },
    subdiv1: {
        width: '33vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    },
    subdiv2: {
        width: '33vw',
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        gap: '8px',
    },
    colordiv: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
}));


export default function Card(props) {
    const classes = useStyles();

    const [selectedColor1, setSelectedColor1] = useState('#217fb5'); // Initial color (#000000 is black)
    const [selectedColor2, setSelectedColor2] = useState('#217fb5');
    const [AppImage, setAppImage] = useState(logoDark);
    const [OrgLightImage, setOrgLightImage] = useState(logoDark);
    const [OrgDarkImage, setOrgDarktImage] = useState(logoDark);
    const [files, setFiles] = useState({});
    const [spin, setSpin] = useState(false);
   

    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);

    const handleAppImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            files["App-icon"] = file;
            setFiles(files);
            const reader = new FileReader();
            reader.onload = (e) => {

                setAppImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleOrgLightImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            files["logo-light"] = file;
            setFiles(files);
            const reader = new FileReader();
            reader.onload = (e) => {
                setOrgLightImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleOrgDarktImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            files["logo-dark"] = file;
            setFiles(files);
            const reader = new FileReader();
            reader.onload = (e) => {
                setOrgDarktImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleEditClick1 = () => {
        // Trigger the file input when the edit button is clicked
        fileInputRef1.current.click();
    };
    const handleEditClick2 = () => {
        // Trigger the file input when the edit button is clicked
        fileInputRef2.current.click();
    };
    const handleEditClick3 = () => {
        // Trigger the file input when the edit button is clicked
        fileInputRef3.current.click();
    };
    const instance = axios.create({
        baseURL: config.aws_api_endpoint,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const handleChange = (event) => {
        setSelectedColor1(event.target.value);
    };

    const handleChange2 = (event) => {
        setSelectedColor2(event.target.value);
    };
    const handleCancel = () => {
        setSelectedColor1('#217fb5')
        setSelectedColor2('#217fb5')
        setOrgLightImage(logoDark)
        setOrgLightImage(logoDark)
        setOrgDarktImage(logoDark)
        props.setOid('')
        props.setTitle('')
    }

    async function fetchData() {
        // const obj = {"App-icon":AppImage,"logo-light":OrgLightImage,"logo-dark":OrgDarkImage}
        try {
            
            if(props.oid && props.title ){
               if(props.oid.length >= 3 && props.title.length >=3){
                setSpin(true);
                const instanceCreation = await instance.post('/org_creation', {
                    "oid": props.oid,
                    "mainColor1": selectedColor1,
                    "mainColor2": selectedColor2,
                    "title": props.title
                })
                if(instanceCreation.data.statusCode !== 200){
                    Swal.fire({
                        title: 'Oops',
                        text: `${instanceCreation.data.body}`,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }); 
                    setSpin(false)
                }
                else{
                    var preSignedURLresponse = await instance.post('/getPreSignedSuperAdmin', {
                        "oid": props.oid,
                        "type": 'OrgImages',
                        "filetype": 'image/png',
                        "filenames": {
                            "App-icon": "App-icon.png",
                            "logo-light": "logo-light.png",
                            "logo-dark": "logo-dark.png"
                        }
                    });
                   // console.log(instanceCreation)
                    if (preSignedURLresponse.data.statusCode === 200) {
                        var response = await axios
                            .put(preSignedURLresponse.data.body['App-icon'], files['App-icon'], {
                                headers: {
                                    "Content-Type": 'image/png',
                                    "Cache-Control": "no-cache no-store must-revalidate",
                                },
                            })
                        response = await axios
                            .put(preSignedURLresponse.data.body['logo-light'], files['logo-light'], {
                                headers: {
                                    "Content-Type": 'image/png',
                                    "Cache-Control": "no-cache no-store must-revalidate",
                                },
                            })
        
                        response = await axios
                            .put(preSignedURLresponse.data.body['logo-dark'], files['logo-dark'], {
                                headers: {
                                    "Content-Type": 'image/png',
                                    "Cache-Control": "no-cache no-store must-revalidate",
                                },
                            })
                        const listContainer = document.createElement('div');
                        listContainer.classList.add('swal-list-container');
    
                        const parentDiv = document.createElement('div');
                       

                        // Create an unordered list and populate it with items
                        const itemListElement = document.createElement('ul');
                        itemListElement.classList.add('swal-list'); // Add a class to the list
                        
                        // Create a list item for OID
                        const oidListItem = document.createElement('li');
                        oidListItem.innerHTML = `<strong>OID:</strong> ${instanceCreation.data.body.oid}`;
                        oidListItem.classList.add('swal-list-item'); // Add a class to the list item
                        itemListElement.appendChild(oidListItem);
                        
                        // Create a list item for URL
                        const curlListItem = document.createElement('li');
                        curlListItem.innerHTML = `<strong>URL:</strong> ${instanceCreation.data.body.domain}`;
                        curlListItem.classList.add('swal-list-item'); // Add a class to the list item
                        itemListElement.appendChild(curlListItem);
                        
                        // Append the unordered list to the parent div
                        parentDiv.appendChild(itemListElement);
                        
                        // Create a div for notes
                        const notes = document.createElement('div');
                        notes.innerHTML = `<p>URL will usually take 8 to 10 minutes to Go Live</p>`;
                        // Append the notes div to the parent div
                        notes.style.marginTop = '1rem';
                        parentDiv.appendChild(notes);
                        
                        // Append the parent div to the listContainer
                        listContainer.appendChild(parentDiv);
                        //listContainer.appendChild(itemListElement);
                       
                     //db tables vieews   creation              
                     const CreationtablesViews = await instance.post('/createTabViews', {
                        "oid": props.oid,
                    })    
                    //console.log(CreationtablesViews)          
                     if(CreationtablesViews.status === 200) {
                        const sqecreation = await instance.post('/createdbSequences', {
                            "oid": props.oid,
                        }) 
                        const procedurecreation = await instance.post('/createdbprocedure', {
                            "oid": props.oid,
                        }) 
                        const functioncreation = await instance.post('/createdbFun', {
                            "oid": props.oid,
                        }) 
                        
                        
                     }  else {
                        Swal.fire({
                            title: 'Oops',
                            text: 'Error while creating database Schema',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                     }
                        // Show the SweetAlert dialog with the list
                        setSpin(false)
                        Swal.fire({
                            title: 'Created Successfully',
                            html: listContainer,
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });
                    }
                } 
               } else {
                Swal.fire({
                    title: 'Oops',
                    text: 'Oid and Browser Title should have minimum of 3 characters',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }); 
               }
               
            } else if(props.oid && !props.title) {
                Swal.fire({
                    title: 'Oops',
                    text: 'please enter Browser Title',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }); 
            }else if(!props.oid && props.title) {
                Swal.fire({
                    title: 'Oops',
                    text: 'please enter Oid',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }); 
               
            }else if(!props.oid && !props.title) {
                Swal.fire({
                    title: 'Oops',
                    text: 'please enter Oid and Browser Title',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });  
            }

        } catch (error) {
            console.error('Error:', error);
        }

    }
    return (
        <>
            <div className={classes.maindiv}>
           {spin && <Backdrop className={classes.backdrop} open={spin}>
                 <CircularProgress color="inherit" />
            </Backdrop>}
                <div className={classes.subdiv}>
                    <input
                        type="file"
                        accept="image/*"
                        id="app-image-upload"
                        className={classes.fileInput}
                        ref={fileInputRef1}
                        onChange={handleAppImageChange}
                    />
                    <label  >
                        App-Icon
                    </label>
                    <img style={{border:'1px solid #217fb5'}}
                        src={AppImage}
                        alt="Selected"
                        className={classes.previewImage}
                    />
                    <EditOutlinedIcon
                        className={classes.editIcon}
                        onClick={handleEditClick1}
                    />
                </div>

                <div className={classes.subdiv1}>
                    <input
                        type="file"
                        accept="image/*"
                        id="app-image-upload"
                        className={classes.fileInput}
                        ref={fileInputRef2}
                        onChange={handleOrgLightImageChange}
                    />
                    <label  >
                       logo - light
                    </label>
                    <img style={{border:'1px solid #217fb5'}}
                        src={OrgLightImage}
                        alt="Selected"
                        className={classes.previewImage}
                    />
                    <EditOutlinedIcon
                        className={classes.editIcon}
                        onClick={handleEditClick2}
                    />
                </div>

                <div className={classes.subdiv2}>
                    <input
                        type="file"
                        accept="image/*"
                        id="app-image-upload"
                        className={classes.fileInput}
                        ref={fileInputRef3}
                        onChange={handleOrgDarktImageChange}
                    />
                    <label>
                    logo - dark
                    </label>
                    <img style={{border:'1px solid #217fb5'}}
                        src={OrgDarkImage}
                        alt="Selected"
                        className={classes.previewImage}
                    />
                    <EditOutlinedIcon
                        className={classes.editIcon}
                        onClick={handleEditClick3}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', gap: '1.2rem', margin: '20px 12px', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ width: '140px' }}>Primary Color</p>
                    <div>
                        <input type="color" placeholder="#000000" value={selectedColor1} onChange={handleChange} style={{ width: '50px', height: '50px', borderRadius: '10px', borderWidth: '1px', padding: '0 0' ,cursor:'pointer'}} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ width: '140px' }}>Secondary Color</p>
                    <div>
                        <input type="color" placeholder="#000000" value={selectedColor2} onChange={handleChange2} style={{ width: '50px', height: '50px', borderRadius: '10px', borderWidth: '1px', padding: '0 0' ,cursor:'pointer'}} />
                    </div>
                </div>
            </div>

            
                <div className="subnote">
                <p><span>Note : </span> "Oid" and "Browser Title" should contain only Alphabets  </p>
                </div>

            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' ,gap:'45px'}}>
                <div >
                    <Button className="button" onClick={handleCancel}>Cancel</Button>
                </div>
                <div>
                    <Button className="button" onClick={fetchData}>Create</Button>
                </div>
                <div >
                    <Button className="button" onClick={props.back}>Back</Button>
                </div>
            </div>
        </>
        // <div className={classes.imageUploader}>
        //   <div className={classes.imgs}>
        //     <div className={classes.inputfield}>
        //       <input
        //         type="file"
        //         accept="image/*"
        //         id="app-image-upload"
        //         className={classes.fileInput}
        //         ref={fileInputRef1}
        //         onChange={handleAppImageChange}
        //       />
        //       <label htmlFor="app-image-upload" className={classes.uploadButton}>
        //         Upload Image
        //       </label>
        //       <div className={classes.imagePreview}>
        //         {AppImage ? (
        //           <>
        //             <img
        //               src={AppImage}
        //               alt="Selected"
        //               className={classes.previewImage}
        //             />
        //             <span className={classes.editButton}>
        //               <EditOutlinedIcon
        //                 className={classes.editIcon}
        //                 onClick={handleEditClick1}
        //               />
        //             </span>
        //           </>
        //         ) : (
        //           <p>No image selected</p>
        //         )}
        //       </div>
        //     </div>
        //   </div>

        //   <div className="imgs">
        //     <div className={classes.inputfield}>
        //       <input
        //         type="file"
        //         accept="image/*"
        //         id="light-image-upload"
        //         className={classes.fileInput}
        //         ref={fileInputRef2}
        //         onChange={handleOrgLightImageChange}
        //       />
        //       <label htmlFor="light-image-upload" className={classes.uploadButton}>
        //         Upload Image
        //       </label>
        //       <div className={classes.imagePreview}>
        //         {OrgLightImage ? (
        //           <>
        //             <img
        //               src={OrgLightImage}
        //               alt="Selected"
        //               className={classes.previewImage}
        //             />
        //             <span className={classes.editButton}>
        //               <EditOutlinedIcon
        //                 className={classes.editIcon}
        //                 onClick={handleEditClick2}
        //               />
        //             </span>
        //           </>
        //         ) : (
        //           <p>No image selected</p>
        //         )}
        //       </div>
        //     </div>
        //   </div>

        //   <div className="imgs">
        //     <div className={classes.inputfield}>
        //       <input
        //         type="file"
        //         accept="image/*"
        //         id="dark-image-upload"
        //         className={classes.fileInput}
        //         ref={fileInputRef3}
        //         onChange={handleOrgDarktImageChange}
        //       />
        //       <label htmlFor="dark-image-upload" className={classes.uploadButton}>
        //         Upload Image
        //       </label>
        //       <div className={classes.imagePreview}>
        //         {OrgDarkImage ? (
        //           <>
        //             <img
        //               src={OrgDarkImage}
        //               alt="Selected"
        //               className={classes.previewImage}
        //             />
        //             <span className={classes.editButton}>
        //               <EditOutlinedIcon
        //                 className={classes.editIcon}
        //                 onClick={handleEditClick3}
        //               />
        //             </span>
        //           </>
        //         ) : (
        //           <p>No image selected</p>
        //         )}
        //       </div>
        //     </div>
        //   </div>
        // </div>
    )
}



