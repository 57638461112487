import React ,{ useState } from 'react';
import logo from '../logo-light.png'
import Card from '../components/Card'
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { awsSignOut, authData } from "../../../redux/auth/authSlice";
import {  useHistory } from "react-router-dom";
import CreateDeletebutton from './CreateDeletebutton';
import DeleteInstance from './DeleteInstance';
import FinalizeProject from './FinalizeProject';


export default function Nav() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  let userDetails = useSelector(authData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttons, setButtons] = useState(true);
  const [showCreateComponent, setShowCreateComponent] = useState(false);
  const [showDeleteComponent,setshowDeleteComponent] =  useState(false);
  const [showFinalizeComponent,setShowFinalizeComponent] =  useState(false);


  const handleCreateInstance = () => {
    setShowCreateComponent(true);
    setButtons(false);
  };
  
  const handleDeleteInstance = () => {
    setshowDeleteComponent(true);
    setButtons(false);
  };
  const handleFinalizeInstance = () => {
    setShowFinalizeComponent(true);
    setButtons(false);
  };

  const handleCardContent = () => {
    setButtons(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    Auth.signOut();
    dispatch(awsSignOut());
    navigate.push("/");
  }
  function back(){
    setButtons(true)
    setShowCreateComponent(false)
    setshowDeleteComponent(false)
    setShowFinalizeComponent(false)
  }
  
function InstanceDetail(){
  const [oid, setOid] = useState('');
  const [title, setTitle] = useState('');
  return (
    <>
     <div className='card'>
    <div className='sub-head'>
          <h2>Instance details</h2>
        </div>
        <form className='forms'>
          <div className='dev'>
            <p className='lables' >
              OID:
            </p>
            <input type='text' className='org-name' value={oid} maxLength="15" onChange={(e) => setOid(e.target.value)}/>
          </div>
          <div className='dev'>
            <p className='lables' >
            Browser TITLE:
            </p>
            <input type='text' className='org-name'  value={title} maxLength="50" onChange={(e) => setTitle(e.target.value)}/>
          </div>
        </form>
        <Card oid={oid} title={title} setOid={setOid} setTitle={setTitle} back={back}/>
        </div>
    </>
  )
} 
function MainCard(props) {
  return (
       <>
       {buttons && <CreateDeletebutton handleCreateInstance={handleCreateInstance} handleDeleteInstance ={handleDeleteInstance} handleFinalizeInstance={handleFinalizeInstance} />} 
       {showCreateComponent && <InstanceDetail back ={back} />}
       {showDeleteComponent && <DeleteInstance  back={back} /> }
       {showFinalizeComponent && <FinalizeProject back={back}/> }
    </>
  )
}
  
  return (
    <>
      <div className="navbar">
        <div className='logo' style={{ width:'80%'}}>
          <img src={logo} alt="img" style={{ height: "2.25rem", marginLeft: "1.8rem", cursor: "pointer"}} />
        </div>
        <div className='mainprofile' style={{display:'flex',justifyContent:'right', width:'20%'}}>
         
        <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    paddingRight: "10px",
                    color: "white",
                    fontWeight: "100",
                    fontFamily: "Nunito Sans",
                  }}
                >
                  {userDetails?.midLevelUserDetails?.first_name ||
                    userDetails?.name || ""}
                </Typography>
                <AccountCircle style={{ color: "white", width: "2rem", height: "2rem"}} />
              </IconButton>
              <Menu
                style={{
                  marginTop: "40px",
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose} //handleClose
              >
                <MenuItem onClick={logout} style={{padding:"0rem 1rem"}}>Logout</MenuItem> 
              </Menu>
            </div>
        </div>
      </div>
      <MainCard />
    </>
  )
}
