import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { authData } from "./redux/auth/authSlice";
import Login from "./components/pages/Login/login";
import SuperAdmin from "./components/SuperAdminPges/components/Nav";
 import { ThemeProvider } from "styled-components";
import { Theme } from "./config/aws-exports";
function App() {
  const userDetails = useSelector(authData);
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <Router>
          {userDetails === 0 ? (
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          ) : (
            <Switch>
              {/* <Route exact path="/">
             { userDetails !== <Login /> }
            </Route> */}
              <Route exact path="/">
                <SuperAdmin/>
              </Route>
            </Switch>
          )}
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
