import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    adminValue: 0,
  },
  reducers: {
    
    SignIn: (state, action) => {
      state.adminValue = 1;
    },
    SignOut: (state) => {
      state.adminValue = 0;
    },
  },
});

export const { SignIn, SignOut } = loginSlice.actions;
export const loginState = (state) => state.auth.adminValue;

export default loginSlice.reducer;
