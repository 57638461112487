import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
//import thunk from 'redux-thunk';

import authReducer from "../auth/authSlice";
import loginReducer from "../auth/loginSlice";

const reducers = combineReducers({
  adminAuth: authReducer,
  login: loginReducer,
});

const persistConfig = {
  key: "adminRoot",
  storage, // define which storage to use
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
 
});

export default store;
