import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    adminValue: 0,
  },
  reducers: {
    
    awsSignIn: (state, action) => {
      state.adminValue = { ...action.payload };
    },
    awsSignOut: (state) => {
      state.adminValue = 0;
    },
  },
});


export const { awsSignIn, awsSignOut } = authSlice.actions;


export const authData = (state) => state.adminAuth.adminValue;

export default authSlice.reducer;
