export const color = {
  primary: "#ff6f4c",
  secondary: "#285780",
};
export const Theme = {
  color: {
    primary: "#093055",
    secondary: "#f18121",
    iconColor: "#1C6D9C",
    secondaryLight: "rgba(40, 87, 129, 0.2)",
    text: "#000",
    white: "#fff",
    primaryMain: "#217fb5",
  },
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
};

const endev = {
  env: "dev",
  main_color_1: "#093055",
  main_color_2: "#093055",
  SCHEMA_NAME: "SuperAdmin",
  platform_main_theme: "linear-gradient(#093055, #093055)",
  button_color_web: "#0F80A4",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "88ieb14e85",
      name: "EN-AUTHORING",
      description: "",
      endpoint:
        "https://88ieb14e85.execute-api.ap-south-1.amazonaws.com/ED_DEVELOP",
      region: "ap-south-1",
    },
  ],
  aws_cloud_logic_custom_name: "EN-AUTHORING",
  aws_cognito_region: "ap-south-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-enabled",
  aws_content_delivery_bucket_region: "ap-south-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d177q4h9hpq2tk.cloudfront.net",
  DOMAIN: "d177q4h9hpq2tk.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "ap-south-1",
  aws_resource_name_prefix: "en-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-south-1_gyi16Lpyz",
  aws_user_pools_web_client_id: "7m4igckin2el8qeejdobnivm8p",
  aws_api_endpoint: "https://88ieb14e85.execute-api.ap-south-1.amazonaws.com/ED_DEVELOP",
  aws_org_id: "ENABLED",
  appVersion: 2.3
};
const excelearnProd = {
  main_color_1: "#093055",
  main_color_2: "#f18121",
  platform_main_theme: "linear-gradient(#093055, #093055)",
  button_color_web: "#0f80a4",
  SCHEMA_NAME: "SuperAdmin",
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "us-east-1:b3f3d2c7-4164-483a-80fb-dd0a657676f8",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "ap-south-1_SSD1G6Lrb",
  aws_user_pools_web_client_id: "3bkbmhoht5s82o28bmehc0rn3u",
  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "ENABLED",
  learningpath: false,
  liveSession: false,
  mt_id: 1,
  aws_cloudfront_url: "https://www.excelearn.enabled.live/",
  aws_cloudfront_lurl: "https://www.excelearn.enabled.live",
  DOMAIN: "d3udcl5kgfjqgz.cloudfront.net",
  aws_api_name: "EN-AUTHORING",
  aws_api_endpoint:
    "https://byfjjm9pu2.execute-api.ap-south-1.amazonaws.com/EN-Prod",
  aws_cloud_logic_custom_name: "EN-AUTHORING",
  aws_cloud_logic_custom: [
    {
      id: "byfjjm9pu2",
      name: "EN-AUTHORING",
      description: "",
      endpoint:
        "https://byfjjm9pu2.execute-api.ap-south-1.amazonaws.com/EN-Prod",
      region: "us-east-1",
    },
  ],
  appVersion: 2.3
};

export default excelearnProd;
