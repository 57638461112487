import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import axios from 'axios';


export default function CreateDeletebutton(props) {
  return (
    <>
       <div className="buttonscontainer">
       <div>
        <h2>WelCome to SuperAdmin</h2>
        </div>
        <div className="buttons">
        <Button variant = "contained"
        color = "primary"
        onClick = {props.handleCreateInstance} >
        Create Instance 
        </Button> 

        <Button variant = "contained"
        color = "secondary"
        onClick ={()=> {
           props. handleDeleteInstance();
        }}>
        Delete Instance
        </Button> 
        <Button variant = "contained"
        color = "success"
        onClick ={()=> {
           props. handleFinalizeInstance();
        }}>
        Finalize project
        </Button>
        </div>
        </div>
    </>
  )
}
