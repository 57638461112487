import React ,{ useState ,useEffect} from 'react';
import { Button } from "@mui/material";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import Swal from 'sweetalert2'; 
import config from '../../../config/aws-exports';

export default function DeleteInstance(props) {
    const [oiddel, setOiddel] = useState([]);
    const [spin, setSpin] = useState(false);
    const [selectedOid,setSelectedOid] = useState('')

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
          }
    }))
    const classes = useStyles();
   
    const handleCanceldel =()=>{
        setSelectedOid('')
    }
    const instancedel = axios.create({
        baseURL: config.aws_api_endpoint,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
      const collectOids = async ()=>{
          const instanceCreation = await instancedel.post('/collectInstances');
          setOiddel(instanceCreation.data)
      }

      useEffect(() => {
        collectOids();
      }, []);

      async function fetchData() {
        try{
            
            if(selectedOid && selectedOid !==''){
                setSpin(true)
                    const instanceCreation = await instancedel.post('/finalizeInstance ', {
                        "oid": selectedOid,
                    })
                    console.log(instanceCreation)
                    if(instanceCreation.status !== 200){
                        Swal.fire({
                            title: 'Oops',
                            text: `${instanceCreation.data.body}`,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }); 
                        setSpin(false)
                    } else {

                        Swal.fire({
                            title: 'Instance Finalized',
                            text: `Instance Name : ${selectedOid}`,
                            icon: 'success',
                            confirmButtonText: 'OK'  
                        });
                        await collectOids()
                        setSelectedOid('')
                        setSpin(false)
                    }
                   } else {
                    Swal.fire({
                        title: 'Oops',
                        text: 'please select an Instance',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }); 
                    setSpin(false)
                   }
        }catch(error){
            console.log(error)
        }
    }
  return (
    <>
    {spin && <Backdrop className={classes.backdrop} open={spin} >
                 <CircularProgress color="inherit" />
            </Backdrop>}
    <div className='card'>
    <div className='sub-head'>
          <h2>Instance details</h2>
        </div>
        <form className='forms'>
          <div className='dev'>
            <p className='dellables' >
              OID:
            </p>
            {/* <input type='text' className='org-name' value={oiddel} maxLength="15" onChange={(e) => setOiddel(e.target.value)}/> */}
            <select value={selectedOid} className='org-name' onChange={(e) => setSelectedOid(e.target.value)}>
            <option value=""></option>
               {oiddel.map((option, index) => (
               <option key={index} value={option}>
               {option}
          </option>
        ))}
      </select>
          </div>
          </form>
          <div className="subnote">
                <p><span>Note : </span> Once the instance is finalized, it cannot be deleted</p>
        </div> 
        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' ,gap:'45px'}}>
                <div >
                    <Button className="button" onClick={handleCanceldel}>Cancel</Button>
                </div>
                <div>
                    <Button className="button" onClick={fetchData}>Finalize</Button>
                </div>
                <div >
                    <Button className="button" onClick={props.back}>Back</Button>
                </div>
        </div>
        </div>  
    </>
  )
}
